import React from "react"

const QuoteMark = props => {
  return (
    <svg
      width="163"
      height="127"
      viewBox="0 0 163 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M163 52.6926H125.829L158.848 0H121.677L88.6582 52.6926V127H163V52.6926Z"
        fill="currentColor"
      />
      <path
        d="M74.3415 52.6926H37.1708L70.1893 0H33.0185L0 52.6926V127H74.3415V52.6926Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default QuoteMark

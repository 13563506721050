import React from "react"
import { Link, graphql } from "gatsby"
import ThemeContext from "../context/ThemeContext"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ArticleCard from "../components/ArticleCard"
import Container from "../components/Container"
import Arrow from "../components/Arrow"
import QuoteMark from "../components/QuoteMark"

const BlogIndex = props => {
  const { previousPagePath, nextPagePath } = props.pageContext

  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo title="Blog" />
          <Container>
            <div className="md:grid md:grid-cols-8 pb-16 md:gap-12">
              <div className="md:col-span-2 flex flex-col justify-between">
                <div className="text-[4.5rem] md:text-7xl font-light">Blog</div>
                <QuoteMark className="hidden md:block w-full h-auto max-w-[15rem] self-end" />
              </div>
              <div className="md:col-span-6 mt-6 md:mt-12">
                {props.data.posts.edges.map((article, idx) => (
                  <ArticleCard key={idx} article={article.node} />
                ))}

                <div className="flex justify-between text-xl font-light border-t border-current pt-3 mt-20">
                  {previousPagePath ? (
                    <Link to={previousPagePath} className="flex items-center">
                      <Arrow className="rotate-180 mr-3" />
                      <div>Newer posts</div>
                    </Link>
                  ) : (
                    <div></div>
                  )}
                  {nextPagePath ? (
                    <Link to={nextPagePath} className="flex items-center">
                      <div>Older posts</div>
                      <Arrow className="ml-3" />
                    </Link>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    posts: allStrapiArticle(
      sort: { fields: PublishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
  }
`

import React from "react"
import { Link, graphql } from "gatsby"
import LinkArrow from "./LinkArrow"

const ArticleCard = ({ article }) => {
  return (
    <div className="lg:grid lg:grid-cols-6 lg:gap-x-5 lg:gap-y-5 font-light mb-12">
      <div className="inline-block mr-8 lg:col-span-1 uppercase text-sm tracking-widest pt-2">
        {article.PublishDate}
      </div>
      <div className="inline-block lg:col-span-1 pt-2">
        <div className="flex gap-2 flex-wrap">
          {article.categories &&
            article.categories.map((cat, idx) => (
              <div
                key={idx}
                className="border rounded-full inline-block px-1.5 uppercase text-sm tracking-widest"
              >
                {cat.Name}
              </div>
            ))}
        </div>
      </div>
      <div className="lg:col-span-4 mt-6 lg:mt-0">
        <h3 className="text-[1.75rem] md:text-3xl leading-tight mb-3">
          {article.ExternalPostUrl ? (
            <a href={article.ExternalPostUrl} target="_blank" rel="noreferrer">
              {article.Title}
            </a>
          ) : (
            <Link to={`/blog/${article.Slug}`}>{article.Title}</Link>
          )}
        </h3>
        <p className="mb-2">{article.Description}</p>
        {article.ExternalPostUrl ? (
          <a
            className="font-medium inline-block"
            href={article.ExternalPostUrl}
            target="_blank"
            rel="noreferrer"
          >
            Read more
            <LinkArrow className="ml-3 inline-block" />
          </a>
        ) : (
          <Link
            className="font-medium inline-block"
            to={`/blog/${article.Slug}`}
          >
            Read more
          </Link>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    Slug
    PublishDate(formatString: "D.MMM.YYYY")
    Description
    Title
    ExternalPostUrl
    categories {
      Name
    }
  }
`

export default ArticleCard
